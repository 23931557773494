import { css, createGlobalStyle } from "styled-components";
import "./FontFaces.css";

const sansSerifFallback = "'Helvetica Neue', 'Roboto', 'sans-serif'";

const serifFallback = "'Times', 'Times New Roman', 'serif'";

export const sansSerifFace = `'Open Sans', ${sansSerifFallback}`;
export const serifFace = `'Bentham', ${serifFallback}`;

export const fontWeights = {
  light: 300,
  regular: 400,
  bold: 700,
};

export const body = css`
  font-size: 16px;
`;

export const header1 = css``;
export const header2 = css``;
export const header3 = css``;
export const header4 = css``;
export const header5 = css``;
export const header6 = css``;
export const subheader = css``;
export const copy = css``;
export const accents = css``;

export const GlobalType = createGlobalStyle`
  body {
    ${body};
    font-family: ${sansSerifFace}
  }
`;
