import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Title = styled.span`
  font-weight: 700;
`;

export const Heading = styled.a`
  cursor: pointer;
  display: inline-block;
  padding-bottom: 0;
`;

export const Body = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  height: 70px;
`;

export const Card = styled.div`
  border: 2px solid ${Colors.primary.black};
  width: 30%;
  border-bottom: 1px solid ${Colors.primary.black};
  height: 185px;
  background-color: white;
  font-weight: 300;
  margin: 5px;
  position: relative;
  cursor: pointer;

  a {
    display: flex;

    justify-content: flex-start;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${Colors.primary.black};
    > div {
      margin: 5px;
    }
  }
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
      width: 100%;

  }
`;
export const SummaryText = styled.div`
  
  flex-grow: 1;
  align-self: flex-start;
  width: 80%;
  overflow-wrap: break-word;
`;

export const Details = styled.div`
  background-color: ${Colors.primary.black};
  display:flex;
`;
export const BottomDetail = styled.div`
  display: flex;
  background-color: ${Colors.primary.black};
  color: ${Colors.primary.white};
  justify-content: space-between;
  padding:3px 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Row = styled.div`

    position:absolute;
    bottom: 0;
    width: 100%;
    font-size: 12px;
  
  
`;