import React from "react";
import PropTypes from "prop-types";

import ResultsTypeHeader from "../ResultsTypeHeader/ResultsTypeHeader";
import ResultsExpandButton from "../ResultsExpand/ResultsExpand";

import * as S from "./ResultsRowHeader.styles";

const ResultsRowHeader = ({
  type,
  count,
  isExpanded,
  shouldExpand,
  handleToggle,
}) => {
  return (
    <>
      <S.Header>
        <ResultsTypeHeader type={type} count={count} />
        <ResultsExpandButton
          shouldExpand={shouldExpand}
          isExpanded={isExpanded}
          handleToggle={handleToggle}
        />
      </S.Header>
    </>
  );
};

ResultsRowHeader.propTypes = {
  type: PropTypes.string,
  count: PropTypes.number,
  shouldExpand: PropTypes.bool,
  handleToggle: PropTypes.func,
};

ResultsRowHeader.defaultProps = {
  type: "Result Type",
  count: 0,
  shouldExpand: false,
};

export default ResultsRowHeader;
