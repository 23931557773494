import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../UserContext";

import * as S from "./Authbutton.styles";

const AuthButton = () => {
  const { auth, user, signin, signout } = useContext(UserContext);

  return (
    <>
      {auth && user ? (
        <>
          <S.AuthedButton onClick={signout}>
            <FontAwesomeIcon icon={faUser} /> Log out
          </S.AuthedButton>
        </>
      ) : (
        <>
          <S.UnauthedButton onClick={signin}>
            <FontAwesomeIcon icon={faUser} /> Log in&nbsp;
          </S.UnauthedButton>
        </>
      )}
    </>
  );
};

export default AuthButton;
