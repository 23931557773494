import React from "react";
import PropTypes from "prop-types";
import * as S from "./TextResultCard.styles";
import { textTruncate } from "../../utils/text-utils";
import renderHTML from 'react-render-html';

const TextResultCard = ({ result }) => {
  return (
    <S.Card>
      <a href={result.source} target="_blank" rel="noopener noreferrer">
        <S.Title>{textTruncate(result.title, 40)}</S.Title>
        <br />
        <S.Body>{renderHTML(result.text??'')}</S.Body>
      </a>
    </S.Card>
  );
};

TextResultCard.propTypes = {
  result: PropTypes.object,
};

TextResultCard.defaultProps = {
  result: {
    title: `Title`,
    source: `www.google.com`,
    text: `text`,
    dealCompany: ``,
  },
};

export default TextResultCard;
