import styled from "styled-components";

import * as Colors from "../../styles/Colors";

export const Container = styled.div`
  background-color: ${Colors.primary.black};
  width: 120px;
  height: 110px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  border-top: 2px solid ${Colors.grays.medium};
  height: 1px;
  width: 60px;
`;

export const Icon = styled.div`
  background-color: ${Colors.primary.black};
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items center;
`;
