import React, {useState, useEffect, useContext, useCallback} from "react";
import SearchContext from "../../SearchContext";
import PropTypes from "prop-types";
import * as S from "./AdvancedSearchButton.styles";

const AdvancedSearchButton = ({ 
    resultType
}) => {

const { term } = useContext(SearchContext);
const [uri, setUri] = useState("");
const [earlyDealsUri, setEarlyDealsUri] = useState("");
const [matureDealsUri, setMatureDealsUri] = useState("");
const [showDropdown, setshowDropdown] = useState(false)

const setDealsUri = useCallback (() => {
    setEarlyDealsUri(`https://thg.4degrees.ai/deal_list?list=1056&search=${term}&open_filter=true`);
    setMatureDealsUri(`https://thg.4degrees.ai/deal_list?list=1293&search=${term}&open_filter=true`);
}, [term]);

const getURI = useCallback (() => {
    switch (resultType) {
        case "Deals":
            setUri(`https://heritage.affinity.co/dashboard`)
            break;
        case "Contacts":
            setUri(`https://heritage.affinity.co/persons`)
            break;
        case "Companies":
            setUri(`https://heritage.affinity.co/companies`)
            break;
        case "Insights":
            setUri(`https://thg.bloomfire.com/search?query=${term}`)
            break;
        default:
            break;
    }
}, [term, resultType]);

useEffect(() => {
    if(resultType === '_Deals') {
        setDealsUri();
    } else {
        getURI(resultType);
    }

}, [term, getURI, setDealsUri, resultType]);


const handleDropdown = (evt) => {
    setshowDropdown(!showDropdown);
}

window.onclick = function(evt) {
    if(!evt.target.matches('.deals-dropdown-button')){
        setshowDropdown(false);
    }
}

  return (
      <div style={{ padding: "5px" }}>
          {resultType === "_Deals" ?
                <S.DealSearchButton className='deals-dropdown-button' onClick={handleDropdown}>
                    Advanced Search
                    <S.DealSearchDropDown show={showDropdown}>
                        <S.DealSearchLink href={earlyDealsUri} target="_blank" rel="noopener noreferrer">
                            Early Stage
                        </S.DealSearchLink>
                        <S.DealSearchLink href={matureDealsUri} target="_blank" rel="noopener noreferrer">
                            Mature
                        </S.DealSearchLink>
                    </S.DealSearchDropDown>
                </S.DealSearchButton>
                :
                <S.AdvSearchButton href={uri} target="_blank" rel="noopener noreferrer">
                    Advanced Search
                </S.AdvSearchButton>
            }
      </div>
  );
};

AdvancedSearchButton.propTypes = {
  resultType: PropTypes.string
  // term: PropTypes.string,
};

AdvancedSearchButton.defaultProps = {};

export default AdvancedSearchButton;
