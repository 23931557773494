import React from "react";
import PropTypes from "prop-types";
import * as S from "./QuickLinkCard.styles";

const QuickLinkCard = ({ link, title, logo }) => {
  return (
    <>
      <div>
        <div style={{ "text-align": "center", "font-weight": "normal", "text-transform": "none" }}>
          <span >{title}</span>
        </div>
        <S.Card>


          {link && logo ? (

            <div>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <img style={{ "max-width": "275px" }} alt={title} src={logo} />
              </a>
            </div>
          ) : (
              <div>
                <span>{title}</span>
              </div>
            )}
        </S.Card>
      </div>
    </>
  );
};

QuickLinkCard.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  logo: PropTypes.string,
};

QuickLinkCard.defaultProps = {
  link: "",
  title: "Search",
  logo: "",
};

export default QuickLinkCard;
