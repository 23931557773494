import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { UserProvider } from "./UserContext";
import { SearchProvider } from "./SearchContext";
import { Landing } from "./pages/Landing";
import { Results } from "./pages/Results";

const App = () => {
  return (
    <>
      <Router>
        <UserProvider>
          <SearchProvider>
            <Switch>
              <Route exact path="/">
                <Landing />
              </Route>
              <Route exact path="/results" component={Results} />
              <Redirect to="/" />
            </Switch>
          </SearchProvider>
        </UserProvider>
      </Router>
    </>
  );
};

export default App;
