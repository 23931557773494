import styled from "styled-components";

export const Title = styled.span`
  font-size: 1.2em;
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
  text-transform: uppercase;
`;

export const Container = styled.div`
  /* border: 1px solid red; */
`;
