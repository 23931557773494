import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Container = styled.div`
  background-color: ${Colors.primary.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px;
  padding-left: 6px;
  border-radius: 3em;
  cursor: pointer;
`;

export const Label = styled.span`
  text-transform: uppercase;
  color: ${Colors.primary.gray};
  font-size: 0.9em;
`;
