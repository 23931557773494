import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const ResultsPill = styled.span`
  background-color: ${Colors.primary.black};
  color: ${Colors.primary.gray};
  font-size: 1.2em;
  padding: 3px 10px; /* Works for single and multiple digit */
  border-radius: ${(props) => (props.single ? "50%" : "1.1em")};
  display: inline-block;
  text-align: center;
`;
