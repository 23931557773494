import React from "react";
import PropTypes from "prop-types";
import RefineMenuIcon from "../RefineToggleIcon/RefineMenuIcon";
import * as S from "./RefineToggleButton.styles";

const RefineToggleButton = ({ isActive, label, icon, handleToggle }) => {
  return (
    <S.Container onClick={handleToggle} active={isActive}>
      <S.Highlight active={isActive}>
        <S.Icon>
          <RefineMenuIcon active={isActive} icon={icon} />
        </S.Icon>
        <S.Label active={isActive}>{label}</S.Label>
      </S.Highlight>
    </S.Container>
  );
};

RefineToggleButton.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  handleToggle: PropTypes.func,
};
RefineToggleButton.defaultProps = {
  isActive: false,
  label: `Refinement`,
  icon: `refinement`,
};

export default RefineToggleButton;