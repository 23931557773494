import React from "react";
import PropTypes from "prop-types";
import * as S from "./CompanyLogo.styles";
import Logo from "./icon_no_logo_business.png";

const CompanyLogo = ({ image, alt }) => {
  return (
    <S.Icon>
      {image &&
      alt &&
      (image.indexOf("http") === 0 || image.indexOf("http") === 0) ? (
        <img alt={alt} src={image} />
      ) : (
        <img alt={alt} src={Logo} />
      )}
    </S.Icon>
  );
};

CompanyLogo.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
};

CompanyLogo.defaultProps = {
  image: undefined,
  alt: "Company Logo",
};

export default CompanyLogo;
