import React from "react";
import logo from "./logo_thg_quick_link.png";
import * as S from "./Logo.styles";

const Logo = () => {
  return (
    <S.LogoBox>
      <img alt="The Heritage Group" src={logo} />
    </S.LogoBox>
  );
};

export default Logo;
