import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const LogoBox = styled.div`
  background-color: ${Colors.primary.white};
  width: 110px;
  /* padding: 5px; */
  margin-left:10px;
  float:right; clear:right;
`;
