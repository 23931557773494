import React, { useContext} from "react";
import PropTypes from "prop-types";
import RefineOption from "../RefineOption/RefineOption";
import UserContext from "../../UserContext";
import * as S from "./RelationshipsOptions.styles";

const RelationshipOptions = ({
  options,
  handleContactsToggle,
  handleCompaniesToggle,
  handleDealsToggle,
}) => {
  const { contacts, companies, deals } = options;
  const { dealsAndListsEnabled } = useContext(UserContext);

  return (
    <S.Container >
      <S.Header>
        <S.Title>Relationships</S.Title>
      </S.Header>
      <S.Options>
        <RefineOption
          handleToggle={handleContactsToggle}
          selected={contacts}
          label="Contacts"
        />
        <RefineOption
          handleToggle={handleCompaniesToggle}
          selected={companies}
          label="Companies"
        />
        {dealsAndListsEnabled &&
        <RefineOption
          handleToggle={handleDealsToggle}
          selected={deals}
          label="Deals"
        />
        }
      </S.Options>
    </S.Container>
  );
};

RelationshipOptions.propTypes = {
  options: PropTypes.object,
  handleContactsToggle: PropTypes.func,
  handleCompaniesToggle: PropTypes.func,
  handleDealsToggle: PropTypes.func
};

RelationshipOptions.defaultProps = {
  options: {
    contacts: true,
    companies: true,
    deals: true
  },
};

export default RelationshipOptions;
