import React from "react";
import PropTypes from "prop-types";
import Icon from "./icon_no_photo.png";
import * as S from "./ProfilePic.styles";

const ProfilePic = ({ image, alt }) => {
  return (
    <>
      {image && alt ? (
        <S.Profile>
          <img src={image} alt={alt} />
        </S.Profile>
      ) : (
        <S.Profile>
          <img src={Icon} alt={alt} />
        </S.Profile>
      )}
    </>
  );
};

ProfilePic.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
};

ProfilePic.defaultProps = {
  image: undefined,
  alt: "Picture",
};

export default ProfilePic;
