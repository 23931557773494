import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Colors from "../../styles/Colors";

export const IconWrapper = styled.div`
  color: ${Colors.primary.black};
  height: 30px;
  width: 30px;
  position: relative;
  padding-top: 8px;
`;

export const IconStack = styled.div`
  height: 30px;
  width: 30px;
  position: relative;
  cursor: pointer;
`;

export const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
`;

export const Icon2 = styled(FontAwesomeIcon)`
  position: absolute;
  top: 6px;
`;
