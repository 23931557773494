import React, { memo, useState, useEffect, useCallback } from "react";
import ResultsRow from "./ResultsRow";
import ResultsRowHeader from "../ResultsRowHeader/ResultsRowHeader";
import DealsResultCard from "../DealsResultCard/DealsResultCard";
import * as Count from "../../utils/results-count";
import * as S from "./styles.common";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import sorter from 'sort-nested-json';
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaUp, faSortAlphaDown } from "@fortawesome/free-solid-svg-icons";
import AdvancedSearchButton from "../../components/AdvancedSearch/AdvancedSearchButton";

const DealsResults = memo (({ results }) => {
  const [shouldExpand, setShouldExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [origDealsObjs, setOrigDealsObjs] = useState();
  const [sortedDealObjs, setSortDealObjs] = useState();
  const [sortDescending, setSortDescending] = useState(false);
  const [sortFieldState, setSortField] = useState('');
  const [defaultSortState, setDefaultSortState] = useState('Relevance');

  const setResults = useCallback (() => {
    if (results && results.dealsResults) {
      setOrigDealsObjs(_.cloneDeep(results && results.dealsResults));
      setSortDealObjs(results && results.dealsResults);
    }else if (results && results.dealsResults === undefined) {
      setOrigDealsObjs([]);
      setSortDealObjs(undefined);
    }
  }, [results]);

  const sortDeals = (e, sortDir) => {
    if (e !== undefined && e.value !== undefined) {
      let sortField;
      //'Deal Name', 'Company Name', 'Status', 'Stage', 'Creation Date', 'Relevance'
      if (e.value === 'Deal Name') {
        sortField = 'name';
      } else if (e.value === 'Company Name') {
        sortField = 'name';
      } else if (e.value === 'Status') {
        sortField = 'status';
      } else if (e.value === 'Stage') {
        sortField = 'last_interaction';
      } else if (e.value === 'Creation Date') {
        sortField = 'deal_creation';
      } else if (e.value === 'Relevance') {
        sortField = 'relevance';
      }
      if (sortField !== undefined) {
        let objs = sortedDealObjs;
        if (sortField === 'relevance') {
          // we depp clone here becase origObj will eventually get set to the sorted array by reference.
          objs = _.cloneDeep(origDealsObjs);
        } else {

          if (sortDir) {
            objs = sorter.sort(objs).desc(sortField);
          } else {
            objs = sorter.sort(objs).asc(sortField);
          }

        }
        setSortDealObjs(objs);
        setSortField(e.value);
      }
    }
  }
  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const setSortDir = () => {
    setSortDescending(!sortDescending);
    sortDeals({ value: sortFieldState }, !sortDescending);
  }
  useEffect(() => {
    setDefaultSortState('Relevance');
    setResults();
    if (Count.Deals (results) > 6) {
      setShouldExpand(true);
    }
  }, [results, setResults]);

  return (
    <ResultsRow>
      <ResultsRowHeader
        type={`deals`}
        count={Count.Deals(results)}
        shouldExpand={shouldExpand}
        isExpanded={isExpanded}
        handleToggle={handleExpandToggle}
      />

      {sortedDealObjs && (
        <S.SortOrderButton onClick={(e) => setSortDir(e)}>
          {sortDescending ? <FontAwesomeIcon icon={faSortAlphaDown} size="2x" /> : <FontAwesomeIcon icon={faSortAlphaUp} size="2x" />}
        </S.SortOrderButton>
      )}
      {sortedDealObjs && (
        <>
        <S.Filter >
          <Dropdown options={[
            'Deal Name', 'Company Name', 'Status',  'Creation Date', 'Relevance'
          ]} value={defaultSortState} onChange={(e) =>{
            setDefaultSortState(e.value); sortDeals(e);}} placeholder="Select an option" />
        </S.Filter>
        <AdvancedSearchButton resultType='Deals'></AdvancedSearchButton>
        </>
      )}

      {sortedDealObjs && (

        <S.DealsResults isExpanded={isExpanded}>
          {sortedDealObjs.map((deals) => (

            <DealsResultCard
              result={{
                title: deals.name,
                description: deals.description,
                dealCompany: deals.name,
                status: deals.status,
                tags: deals.tag_list,
                logo: deals.logo,
                source: `https://heritage.affinity.co/companies/${deals.entity_id}`,
              }}
              key={deals.id}
            />
          ))}
        </S.DealsResults>
      )}
    </ResultsRow>
  );
});

export default DealsResults;
