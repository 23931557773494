import React from "react";
import PropTypes from "prop-types";
import ResultsCount from "../ResultsCount/ResultsCount";
import * as S from "./ResultsTypeHeader.styles";

const ResultsTypeHeader = ({ type, count }) => {
  return (
    <S.Container>
      <S.Title>{type}</S.Title>
      <ResultsCount count={count} />
    </S.Container>
  );
};

ResultsTypeHeader.propTypes = {
  type: PropTypes.string,
  count: PropTypes.number,
};

ResultsTypeHeader.defaultProps = {
  type: "Results from Heritage",
  count: 0,
};

export default ResultsTypeHeader;
