import styled, {css} from "styled-components";
import * as Colors from "../../styles/Colors";

export const AdvSearchButton = styled.a`
background-color: ${Colors.primary.black};
border: none;
margin: 0px 20px;
display: inline-block;
border-radius: 20px;
padding: 5px 15px;
text-transform: uppercase;
color: white;
text-decoration: none;
`;

export const DealSearchButton = styled.button`
background-color: ${Colors.primary.black};
border: none;
margin: 0px 20px;
display: inline-block;
border-radius: 20px;
padding: 5px 15px;
text-transform: uppercase;
color: white;
font-family: 'Open Sans','Helvetica Neue','Roboto','sans-serif';
font-size: 16px;
text-decoration: none;
position: relative;
outline: none;
cursor: pointer;
`;

export const DealSearchDropDown = styled.div`
display: none;
position: absolute;
background-color: ${Colors.grays.faint};
min-width: 170px;
overflow: auto;
border-radius: 20px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
left: 6px;
top: 32px;
${props =>
  props.show &&
  css`
    display: block;
  `};
`;

export const DealSearchLink = styled.a`
color: ${Colors.primary.black};
padding: 12px 16px;
text-decoration: none;
display: block;
&:hover {
  background-color: ${Colors.grays.light};
}
`;