import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Colors from "../../styles/Colors";

export const Wrapper = styled.div`
  position: relative;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 1.3em;
  color: ${Colors.grays.dark};
`;

export const SearchInput = styled.input`
  width: 100%;
  font-size: 1.2em;
  padding: 10px;
  border-radius: 1.2em;
  /* background-color: ${Colors.primary.gray}; */
  background-color: rgba(198, 202, 205, 0.86);
  color: ${Colors.grays.dark};
  border-color: ${Colors.primary.black};
  &::placeholder {
    text-align: center;
    color: ${Colors.grays.dark};
  }
  &:-ms-input-placeholder {
    text-align: center;
    color: ${Colors.grays.dark};
  }
  &::-ms-input-placeholder {
    text-align: center;
    color: ${Colors.grays.dark};
  }
  &:focus {
    outline: none;
  }
`;
