import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import * as S from "./Footer.styles";

const Footer = () => {
  return (
    <S.FooterBar>
      <div>
        Content &copy;{" "}
        <S.FooterLink
          href="https://www.thgrp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Heritage Group
        </S.FooterLink>{" "}
        |{" "}
        <S.FooterLink
          href="https://www.thgrp.com/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </S.FooterLink>
      </div>
      <div>
        <S.LinkedInIcon
          href="https://www.linkedin.com/company/the-heritage-group"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </S.LinkedInIcon>
      </div>
    </S.FooterBar>
  );
};

export default Footer;
