import React from "react";
import PropTypes from "prop-types";
import * as S from "./LoadingBar.styles";

const LoadingBar = ({ active }) => {
  return <>{active ? <S.LoadingBarActive /> : <S.LoadingBarInactive />}</>;
};

LoadingBar.propTypes = {
  active: PropTypes.bool,
};

LoadingBar.defaultProps = {
  active: false,
};

export default LoadingBar;
