import React from "react";
import PropTypes from "prop-types";
import * as S from "./MainHeadline.styles";

const MainHeadline = ({ main, children }) => {
  return (
    <S.Container>
      <S.Headline>{main}</S.Headline>
      <S.HeadlineBody>{children}</S.HeadlineBody>
    </S.Container>
  );
};

MainHeadline.propTypes = {
  main: PropTypes.string,
  children: PropTypes.node,
};

MainHeadline.defaultProps = {
  main: "Headline Lorem",
  children: undefined,
};

export default MainHeadline;
