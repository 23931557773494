import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import SearchContext from "../../SearchContext";
import Logo from "./heritage_group_logo.png";
import * as S from "./MenuHeader.styles";

const MenuHeader = () => {
  const { setTerm } = useContext(SearchContext);
  const history = useHistory();

  const returnHome = () => {
    setTerm("");
    history.push("/");
  };
  return (
    <S.Container>
      <div onClick={returnHome}>
        <S.Icon>
          <img src={Logo} alt="The Heritage Group Logo" width="100" />
        </S.Icon>
        <S.Divider />
      </div>
    </S.Container>
  );
};

export default MenuHeader;
