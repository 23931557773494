export const Relationships = (results) => {
  if (results) {
    if (results.relationships.contacts && results.relationships.companies) {
      return (
        results.relationships.contacts.length +
        results.relationships.companies.length
      );
    } else if (
      results.relationships.contacts &&
      !results.relationships.companies
    ) {
      return results.relationships.contacts.length;
    } else if (
      !results.relationships.contacts &&
      results.relationships.companies
    ) {
      return results.relationships.companies.length;
    }
  }
  return 0;
};
export const Deals = (results) => {
  return (results && results.dealsResults && results.dealsResults.length) || 0;
};

export const Web = (results) => {
  return (results && results.webResults && results.webResults.length) || 0;
};

export const Documents = (results) => {
  return (results && results.docResults && results.docResults.length) || 0;
};

export const Articles = (results) => {
  return (
    (results && results.articleResults && results.articleResults.length) || 0
  );
};
