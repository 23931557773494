import React from "react";
import PropTypes from "prop-types";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import * as S from "./SecondarySearchInput.styles";

const SecondarySearchInput = ({ value, onUpdateSearch, submitForm }) => {
  return (
    <S.Wrapper>
      <S.SearchIcon icon={faSearch} onClick={(e)=>submitForm(e)} />
      <S.SearchInput
        type="text"
        placeholder="Search Documents, Deals, Articles, etc."
        name="Search"
        value={value}
        onChange={(e) => onUpdateSearch(e)}
      />
    </S.Wrapper>
  );
};

SecondarySearchInput.propTypes = {
  value: PropTypes.string,
  onUpdateSearch: PropTypes.func,
};

SecondarySearchInput.defaultProps = {
  value: "",
};

export default SecondarySearchInput;
