import React from "react";
import PropTypes from "prop-types";
import RefineOption from "../RefineOption/RefineOption";
import * as S from "./DocumentOptions.styles";

const DocumentOptions = ({
  options,
  handleWordToggle,
  handlePptToggle,
  handleXlsToggle,
  handlePdfToggle,
  handleOpenWordToggle,
  handleOpenPptToggle,
  handleOpenXlsToggle,
}) => {
  const { word, ppt, xls, pdf, openword, openppt, openxls } = options;
  return (
    <S.Container>
      <S.Header>
        <S.Title>Documents</S.Title>
      </S.Header>
      <S.Options>
        <RefineOption
          handleToggle={handleWordToggle}
          selected={word}
          label="Word Document"
        />
        <RefineOption
          handleToggle={handlePptToggle}
          selected={ppt}
          label="Powerpoint"
        />
        <RefineOption
          handleToggle={handleXlsToggle}
          selected={xls}
          label="Excel"
        />
        <RefineOption
          handleToggle={handlePdfToggle}
          selected={pdf}
          label="Pdf"
        />
        <RefineOption
          handleToggle={handleOpenWordToggle}
          selected={openword}
          label="Open Document"
        />
        <RefineOption
          handleToggle={handleOpenPptToggle}
          selected={openppt}
          label="Open Presentation"
        />
        <RefineOption
          handleToggle={handleOpenXlsToggle}
          selected={openxls}
          label="Open Spreadsheet"
        />
      </S.Options>
    </S.Container>
  );
};

DocumentOptions.propTypes = {
  options: PropTypes.object,
  handleWordToggle: PropTypes.func,
  handlePptToggle: PropTypes.func,
  handleXlsToggle: PropTypes.func,
  handlePdfToggle: PropTypes.func,
  handleOpenWordToggle: PropTypes.func,
  handleOpenPptToggle: PropTypes.func,
  handleOpenXlsToggle: PropTypes.func,
};

DocumentOptions.defaultProps = {
  options: {
    word: true,
    ppt: true,
    xls: true,
    pdf: true,
    openword: true,
    openppt: true,
    openxls: true,
  },
};

export default DocumentOptions;
