import React from "react";
import PropTypes from "prop-types";
import * as S from "./QuickLinksPanel.styles";

const QuickLinksPanel = ({ children }) => {
  return (
    <S.PanelRow>
      <S.PanelRowItems>{children}</S.PanelRowItems>
    </S.PanelRow>
  );
};

QuickLinksPanel.propTypes = {
  children: PropTypes.node,
};

QuickLinksPanel.defaultProps = {
  children: undefined,
};

export default QuickLinksPanel;
