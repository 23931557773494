import React from "react";
import PropTypes from "prop-types";
import * as S from "./DealsResultCard.styles";
import { textTruncate } from "../../utils/text-utils";
import ProfilePic from "../ProfilePic/ProfilePic";


const DealsResultCard = ({ result }) => {
  return (
    <S.Card>
      <div style={{ padding: "5px" }}>
        <a href={result.source} target="_blank" rel="noopener noreferrer">
          <ProfilePic image={result.logo} alt={`Picture of ${result.name}`} />
          <S.SummaryText>
            <S.Title>{textTruncate(result.dealCompany.length > 0 ? result.dealCompany : "Not Available", 40)}</S.Title>
            <br />
            <S.Body>{result.description}</S.Body>
          </S.SummaryText>
        
        </a>
      </div>
      <S.Row>
        <S.BottomDetail>
          <div style={{ textAlign: "center" }}>
            Company
            <br />
            {result.title}
          </div>
          <div style={{ textAlign: "center" }}>
            Status
            <br />
            {result.status}
          </div>
          <div style={{ textAlign: "center" }}>
            Tags
            <br />
            {result.tags.length>0?textTruncate(result.tags,20):"Not Available"}
          </div>
        </S.BottomDetail>
      </S.Row>
    </S.Card>
  );
};

DealsResultCard.propTypes = {
  result: PropTypes.object,
};

DealsResultCard.defaultProps = {
  result: {
    title: `Title`,
    description: `text`,
    source: '',
    dealCompany: ``,
    status: '',
    tags: '',
    logo: ''
  },
};

export default DealsResultCard;
