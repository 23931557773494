import React, { useContext } from "react";
import PropTypes from "prop-types";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import * as S from "./MainSearchInput.styles";
import UserContext from "../../UserContext";

const MainSearchInput = ({ value, onUpdateSearch }) => {
  const { auth } = useContext(UserContext);
  return (
    <>
      {auth ? (
        <S.Wrapper>
          <S.SearchIcon icon={faSearch} />
          <S.SearchInput
            type="text"
            placeholder="Search Documents, Contacts, Web, etc."
            name="Search"
            value={value}
            onChange={(e) => onUpdateSearch(e)}
          />
        </S.Wrapper>
      ) : (
        <S.Wrapper>
          <S.SearchInput
            type="text"
            placeholder="Login to Search"
            name="Search"
            value={value}
            disabled
          />
        </S.Wrapper>
      )}
    </>
  );
};

MainSearchInput.propTypes = {
  value: PropTypes.string,

  onUpdateSearch: PropTypes.func,
};

MainSearchInput.defaultProps = {
  value: "",
};

export default MainSearchInput;
