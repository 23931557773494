import React from "react";
import PropTypes from "prop-types";
import RefineOption from "../RefineOption/RefineOption";
import * as S from "./WebOptions.styles";

const WebOptions = ({
  options,
  handleVenturesToggle,
  handleConstructionToggle,
  handleEnvironmentalToggle,
  handleChemicalsToggle,
  handleCorporateToggle,
  handleThgToggle,
  handleNewsToggle,
}) => {
  const {
    hgventures,
    construction,
    environmental,
    chemicals,
    corporate,
    thg,
    news,
  } = options;
  return (
    <S.Container>
      <S.Header>
        <S.Title>From the web</S.Title>
      </S.Header>
      <S.Options>
        <RefineOption
          handleToggle={handleVenturesToggle}
          selected={hgventures}
          label="HG Ventures"
        />
        <RefineOption
          handleToggle={handleConstructionToggle}
          selected={construction}
          label="Construction"
        />
        <RefineOption
          handleToggle={handleEnvironmentalToggle}
          selected={environmental}
          label="Environmental"
        />
        <RefineOption
          handleToggle={handleChemicalsToggle}
          selected={chemicals}
          label="Chemicals"
        />
        <RefineOption
          handleToggle={handleCorporateToggle}
          selected={corporate}
          label="THG Corporate"
        />
        <RefineOption
          handleToggle={handleThgToggle}
          selected={thg}
          label="THG"
        />
        <RefineOption
          handleToggle={handleNewsToggle}
          selected={news}
          label="News"
        />
      </S.Options>
    </S.Container>
  );
};

WebOptions.propTypes = {
  options: PropTypes.object,
  handleVenturesToggle: PropTypes.func,
  handleConstructionToggle: PropTypes.func,
  handleEnvironmentalToggle: PropTypes.func,
  handleChemicalsToggle: PropTypes.func,
  handleCorporateToggle: PropTypes.func,
  handleThgToggle: PropTypes.func,
  handleNewsToggle: PropTypes.func,
};

WebOptions.defaultProps = {
  options: {
    hgventures: true,
    construction: true,
    environmental: true,
    chemicals: true,
    corporate: true,
    thg: true,
    news: true,
  },
};

export default WebOptions;
