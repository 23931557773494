import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Card = styled.div`
  color: ${Colors.primary.black};
  background-color: white;
  width: 30%;
  display: flex;
  height: 165px;
  margin: 10px;
  position: relative;
  overflow:hidden;
  border: 2px solid ${Colors.primary.black};
  border-bottom: 15px solid ${Colors.primary.black};
  cursor: pointer;
  padding: 8px;
  a {
    text-decoration: none;
    color: ${Colors.primary.black};
    display: flex;
    flex-direction: row-reverse;
  }
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
      width: 100%;

  }
`;

export const Result = styled.div`
  margin-right: 10px;
  flex-grow: 1;
`;

export const Title = styled.span`
  font-weight: 700;
  display: inline-block;
  padding-bottom: 0;
`;

export const Summary = styled.p`
  padding-top: 0;
  margin-top: 5px;
  height: 70px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
`;
