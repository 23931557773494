import React from "react";
import PropTypes from "prop-types";
import * as S from "./DocumentIcon.styles";
import PDF from "./icon_pdf.png";
import WORD from "./icon_word.png";
import PPT from "./icon_powerpoint.png";
import XLS from "./icon_excel.png";
import ODOC from "./icon_open_doc.png";
import OPPT from "./icon_open_pres.png";
import OXLS from "./icon_open_spread.png";

const IconMap = {
  pdf: PDF,
  docx: WORD,
  pptx: PPT,
  xlsx: XLS,
  odt: ODOC,
  odp: OPPT,
  ods: OXLS,
};

const DocumentIcon = ({ type }) => {
  return (
    <S.Icon>
      <img alt={`${type} document result icon`} src={IconMap[type]} />
    </S.Icon>
  );
};

DocumentIcon.propTypes = {
  type: PropTypes.string,
};

DocumentIcon.defaultProps = {
  type: "pdf",
};

export default DocumentIcon;
