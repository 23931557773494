import React from "react";
import PropTypes from "prop-types";
import * as S from "./HeroPanel.styles";

const HeroPanel = ({ children }) => {
  return (
    <S.HeroWrapper>
      <S.HeroImage>{children}</S.HeroImage>
    </S.HeroWrapper>
  );
};

HeroPanel.propTypes = {
  children: PropTypes.node,
};

HeroPanel.defaultProps = {
  children: undefined,
};

export default HeroPanel;
