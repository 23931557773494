import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Header = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${Colors.primary.white};
  border-bottom: 8px solid ${Colors.primary.yellow};
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
`;
