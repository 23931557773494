import styled from "styled-components";
import * as Color from "../../styles/Colors";

export const FooterBar = styled.footer`
  background-color: ${Color.primary.black};
  color: ${Color.primary.gray};
  font-weight: 100;
  font-size: 1.1em;
  height: 80px;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled.a`
  color: ${Color.primary.gray};
  &:hover {
    cursor: pointer;
    color: white;
  }
`;

export const LinkedInIcon = styled(Link)``;

export const FooterLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
