import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Icon = styled.div`
  background-color: ${Colors.primary.black};
  border: none;
  padding: 0;
  margin: 0;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InactiveIcon = styled(Icon)`
  img {
    filter: brightness(60%);
  }
`;

export const ActiveIcon = styled(Icon)``;
