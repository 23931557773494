import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const UnauthedButton = styled.button`
  background-color: ${Colors.primary.yellow};
  border: none;
  margin: 10px 20px;
  display: inline-block;
  border-radius: 20px;
  padding: 8px 20px;
  text-transform: uppercase;
  color: Colors.primary.black;
  &:focus {
    outline: thin dotted;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const AuthedButton = styled.button`
  background-color: ${Colors.primary.black};
  border: none;
  margin: 10px 20px;
  display: inline-block;
  border-radius: 20px;
  padding: 8px 20px;
  text-transform: uppercase;
  color: white;
  &:focus {
    outline: thin dotted;
  }
  &:hover {
    cursor: pointer;
  }
`;
