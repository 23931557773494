import React from "react";
import { useContext } from "react";
import Cookies from 'universal-cookie';
import PropTypes from "prop-types";
import SearchHeader from "../../components/SearchHeader/SearchHeader";
import Logo from "../../components/Logo/Logo";
import OrionLogo from "../../components/Logo/OrionLogo";
import AuthButton from "../../components/AuthButton/AuthButton";
import HeroSearch from "../../components/HeroSearch/HeroSearch";
import QuickLinksPanel from "../../components/QuickLinksPanel/QuickLinksPanel";
import QuickLinkCard from "../../components/QuickLinkCard/QuickLinkCard";
import MainHeadline from "../../components/MainHeadline/MainHeadline";
import Footer from "../../components/Footer/Footer";
import UserContext from "../../UserContext";

const cookies = new Cookies();
const COOKIE_KEY = "_login_redirect";

window.__BUILD_DATE__ = "2021-07-20T19:46:32.122Z";

const Landing = () => {
  const { auth } = useContext(UserContext);

    var url = cookies.get (COOKIE_KEY);

    if (auth && url)
    {
        cookies.remove (COOKIE_KEY);
        window.location.replace (url);
    }

  return (
    <>
      <SearchHeader>
        <Logo />
        <OrionLogo/>
        <AuthButton />
      </SearchHeader>
      <HeroSearch />
      <MainHeadline main="Orion Search">
        <p>Orion is a knowledge sharing platform for The Heritage Group. It is designed as a collaborative system to help employees make better decisions and create sustainable profitable growth for The Heritage Group. The system is comprised of three components:</p>
        <ul>
          <li>Orion Search: A one-stop system to search all components of Orion, including Orion Connect, Orion Insights, THG company websites and select news services.</li>
          <li>	Orion Connect: A relationship network containing people, companies and deals from Orion users.</li>
          <li>Orion Insights: A collaboration space for strategic information on relevant industries, trends, technical information and expertise across THG.</li>
        </ul>

        <p>To begin searching through Orion Search, click the Login button at the top right portion of the page and then enter your search in the box in the middle of the screen. If you would like to go directly to one of the other components, you may click one of the quick links below.</p>

        <p>All information in Orion is considered confidential and the property of The Heritage Group and its companies. If you are interested in accessing the site, please email <a href="mailto:Orion@thgrp.com">Orion@thgrp.com</a> or call Mike O’Bryan at <a href="tel:+13174905082">317-490-5082</a>.</p>

      </MainHeadline>
      <QuickLinksPanel>
        <QuickLinkCard
          title="Contacts, Companies & Deals"
          logo="orion_connect_small.png"
          link="https://heritage.affinity.co"
        />
        <QuickLinkCard
          title="Curated Content"
          logo="orion_insights_small.png"
          link="https://orioninsights.thgrp.com/groups/51525"
        />

        <QuickLinkCard
          title="Concierge & User Guide"
          logo="orion_help_small.png"
          link="https://thgrp.com/orion"
        />
      </QuickLinksPanel>
      <Footer />
    </>
  );
};

Landing.propTypes = {
  auth: PropTypes.bool,
  user: PropTypes.string,
  signin: PropTypes.func,
  signout: PropTypes.func,
};

Landing.defaultProps = {
  auth: false,
};

export default Landing;
