// Import axios
import axios from "axios";
import { Auth } from "aws-amplify";
import MOCK from "./utils/mock-response.json";

// Constants
const API_URL = `${process.env.REACT_APP_API_URL}`;
const PROFILE_URL = API_URL.replace (/\/[^/]+$/, "/profile");

const BLOMFIRE_SORT_URL = `${process.env.REACT_APP_BLOOMFIRE_URL}`;

const sources = (state) => {
  const source = state.sources[0];

  const activeOptions = source.options.filter((o) => o.active === true);

  if (activeOptions.length > 0) {
    return {
      sources: activeOptions.map((o) => o.id).join(","),
    };
  } else {
    return false;
  }
};

const articles = (state) => {
  const category = state.categories.filter((s) => s.id === "articles")[0];

  const activeOptions = category.options.filter((o) => o.active === true);

  if (activeOptions.length > 0) {
    return {
      articles: activeOptions.map((o) => o.id).join(","),
    };
  } else {
    return false;
  }
};

const relationships = (state) => {
  const category = state.categories.filter((s) => s.id === "relationships")[0];

  const activeOptions = category.options.filter((o) => o.active === true);

  if (activeOptions.length > 0) {
    return {
      relationships: activeOptions.map((o) => o.id).join(","),
    };
  } else {
    return false;
  }
};

const web = (state) => {
  const category = state.categories.filter((s) => s.id === "webcategories")[0];

  const activeOptions = category.options.filter((o) => o.active === true);

  if (activeOptions.length > 0) {
    return {
      webcategories: activeOptions.map((o) => o.id).join(","),
    };
  } else {
    return false;
  }
};

const documents = (state) => {
  const category = state.categories.filter((s) => s.id === "documents")[0];
  const activeOptions = category.options.filter((o) => o.active === true);

  if (activeOptions.length > 0) {
    return {
      documents: activeOptions.map((o) => o.id).join(","),
    };
  } else {
    return false;
  }
};

const searchTerm = (term) => {
  return { term: term.split(" ").join("+") };
};

// request options builder
export const queryParamsBuilder = (state, term = "") => {
  let params = {};
  const DOCUMENTS_STATE = documents(state);
  const WEB_STATE = web(state);
  const RELATIONSHIPS_STATE = relationships(state);
  const ARTICLES_STATE = articles(state);

  // Search Term
  params = Object.assign({}, params, searchTerm(term));

  // Sources
  params = Object.assign({}, params, sources(state));

  // Categories and options
  if (DOCUMENTS_STATE) {
    params = Object.assign({}, params, DOCUMENTS_STATE);
  }
  if (WEB_STATE) {
    params = Object.assign({}, params, WEB_STATE);
  }
  if (RELATIONSHIPS_STATE) {
    params = Object.assign({}, params, RELATIONSHIPS_STATE);
  }
  if (ARTICLES_STATE) {
    params = Object.assign({}, params, ARTICLES_STATE);
  }

  return params;
};

const resultsTransformer = (results) => {
  // @TODO - Truncate to only 10 results
  let response;

  const errors = results.errors;

  const contacts = results.fourDegrees;
  const companies = results.fourDegreesCompanies;
  
  response = Object.assign({}, { relationships: { contacts, companies } });

  // Web only results, only html
  let webResultsFull;
  if (results.webResults) {
    webResultsFull = results.webResults.hits.hit.filter((hit) =>
      hit.fields.media_type.find((type) => type === "html")
    );
  }
  let webResults;
  webResults = webResultsFull;
  // if (webResultsFull.length > 10) {
  //   webResults = webResultsFull.slice(0, 10);
  // } else {
  //   webResults = webResultsFull;
  // }

  // Document only results, NOT html
  let docResultsFull;
  if (results.webResults) {
    docResultsFull = results.webResults.hits.hit.filter((hit) =>
      hit.fields.media_type.find((type) => type !== "html")
    );
  }
  let docResults;
  docResults = docResultsFull;
  // if (docResultsFull.length > 10) {
  //   docResults = docResultsFull.slice(0, 10);
  // } else {
  //   docResults = docResultsFull;
  // }

  const articleResults = results.bloomfire;
  
  let dealsResults;
  if( results.fourdegreesDeals!==undefined ) {
    dealsResults=results.fourdegreesDeals;
  }

  response = Object.assign(
    {},
    response,
    { permissions: results.permissions },
    { webResults },
    { docResults },
    { articleResults },
    { dealsResults },
    { errors }
  );

  return response;
};

export const GetProfile = () => {
    const KEY = process.env.REACT_APP_API_KEY;

    return Auth.currentSession().then((session) => {
      const sessionToken = session.idToken.jwtToken;
      const user = session.idToken.payload.email;

      return axios
        .get(`${PROFILE_URL}`, {
          headers: {
            "x-api-key": KEY,
            Authorization: sessionToken,
            "Content-Type": "application/json",
            user: user,
          },
          crossDomain: true,
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => console.error(e));
    });

};


export const Search = (query) => {
  if (process.env.REACT_APP_DEBUG_SESSION) {
    // Return a mock result
    const baseResult = MOCK;
    const result = resultsTransformer(baseResult);
    return Promise.resolve(result);
  } else {
    const KEY = process.env.REACT_APP_API_KEY;
    return Auth.currentSession().then((session) => {
      const sessionToken = session.idToken.jwtToken;
      const user = session.idToken.payload.email;
      //const user = 'jon@thgrp.com';
      //http://localhost:3000/dev/search-data
      return axios
        .get(`${API_URL}`, {
          params: query,
          headers: {
            "x-api-key": KEY,
            Authorization: sessionToken,
            "Content-Type": "application/json",
            user: user,
          },
          crossDomain: true,
        })
        .then((response) => {
          return resultsTransformer(response.data);
        })
        .catch((e) => console.error(e));
    });
  }
};

export const SearchBloomfire=(searchTerm,sortOrder)=> {
  const KEY = process.env.REACT_APP_API_KEY;
  return Auth.currentSession().then((session) => {
    const sessionToken = session.idToken.jwtToken;
    const user = session.idToken.payload.email;
    //http://localhost:3000/dev/bloomfire-sort
    return axios
      .get(`${BLOMFIRE_SORT_URL}?searchTerm=${searchTerm}&sort=${sortOrder}`, {
        
        headers: {
          "x-api-key": KEY,
          Authorization: sessionToken,
          "Content-Type": "application/json",
          user: user,
        },
        crossDomain: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => console.error(e));
  });
};
