import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 40px 20px 40px;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const Headline = styled.span`
  text-transform: uppercase;
  font-weight: 700;
`;

export const HeadlineBody = styled.div`
  font-weight: 100;
`;
