// The purpose is to override the Federation details
// Per environment in amplify, git branches, and aws accounts

// We import the generated aws-exports.js config file.
// It's just a default object export.
import awsmobile from "../aws-exports";

// We then add some properties to it, and export that
// new object, and this is passed to Amplify.configure(config)
// instead of the aws-exports.js file
const awsconfig = awsmobile;

awsconfig.oauth.responseType = process.env.REACT_APP_OAUTH_RESPONSE_TYPE;
awsconfig.oauth.client_id = process.env.REACT_APP_OAUTH_CLIENT_ID;
awsconfig.oauth.redirectSignIn = process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN;
awsconfig.oauth.redirectSignOut = process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT;

export default awsconfig;
