import React, { useContext } from "react";
import PropTypes from "prop-types";
import HeroPanel from "../HeroPanel/HeroPanel";
import MainSearchInput from "../MainSearchInput/MainSearchInput";
import SearchContext from "../../SearchContext";
import * as S from "./HeroSearch.styles";

const HeroSearch = ({ handleSearch }) => {
  const { term, setTerm, onHandleFirstSearch } = useContext(SearchContext);

  const handleUpdate = (e) => {
    setTerm(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (term === "") {
      return;
    }

    onHandleFirstSearch();
  };
  return (
    <S.Container>
      <HeroPanel>
        <form onSubmit={handleSubmit}>
          <MainSearchInput onUpdateSearch={handleUpdate} value={term} />
        </form>
      </HeroPanel>
    </S.Container>
  );
};

HeroSearch.propTypes = {
  auth: PropTypes.bool,
};

HeroSearch.defaultProps = {
  auth: false,
};

export default HeroSearch;
