import React from "react";
import logo from "./orion_logo_small.png";
import * as S from "./OrionLogo.styles";

const OrionLogo = () => {
  return (
    <S.OrionLogoBox>
      <img style={{ "max-width": "275px" }} alt="Orion" src={logo} />
    </S.OrionLogoBox>
  );
};

export default OrionLogo;
