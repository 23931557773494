import REFINEMENT from "./icon_refine.png";
import REFINEMENT_BLUE from "./icon_refine_blue.png";

const lookup = {
  REFINEMENT,
  REFINEMENT_BLUE
};

const RefineIconMap = (icon) => {
  return lookup[icon.toUpperCase()] || "";
};

export default RefineIconMap;
