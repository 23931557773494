import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Card = styled.div`
  border: 2px solid ${Colors.primary.black};
  width: 30%;
  border-bottom: 1px solid ${Colors.primary.black};
  height: 185px;
  background-color: white;
  font-weight: 300;
  margin: 5px;
  position: relative;
  

  a {
    display: flex;

    justify-content: flex-start;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${Colors.primary.black};
    > div {
      margin: 5px;
    }
  }

  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
      width: 100%;

  }
`;


export const Row = styled.div`

    position:absolute;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    
  
`;
export const Detail = styled.div`
  display: flex;
  background-color: ${Colors.primary.black};
  color: ${Colors.primary.white};
  justify-content: space-between;
  padding:3px 5px;
`;
export const RelationShip = styled.div`
  float:right;
  clear:both;
  top:0;
  right:0;
  padding: 10px;
  max-width: 50px;
  font-size:10px;
`;
export const NumContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`;
export const RelationshipNum = styled.div`
  background-color: ${Colors.primary.black};
  color: ${Colors.primary.white};
  display: block;
  height: 20px;
  width: 20px;
  line-height: 20px;
  -moz-border-radius: 30px; /* or 50% */
  border-radius: 30px; /* or 50% */
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;