import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const LoadingBarInactive = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${Colors.primary.black};
`;

export const LoadingBarActive = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${Colors.primary.black};
  background: linear-gradient(
    124deg,
    #ff2400,
    #e8b71d,
    #e3e81d,
    #1de840,
    #1ddde8,
    #2b1de8,
    #dd00f3
  );
  background-size: 1800% 1800%;
  -webkit-animation: rainbow 5s ease infinite;
  -z-animation: rainbow 5s ease infinite;
  -o-animation: rainbow 5s ease infinite;
    animation: rainbow 5s ease infinite;}
  @-webkit-keyframes rainbow {
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }
  @-moz-keyframes rainbow {
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }
  @-o-keyframes rainbow {
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }
  @keyframes rainbow {
      0%{background-position:0% 82%}
      50%{background-position:100% 19%}
      100%{background-position:0% 82%}
  }
`;
