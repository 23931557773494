import styled from "styled-components";
import * as Colors from "../../../styles/Colors";

export const IconSelected = styled.div`
  background-image: url("./icon_selected.png");
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${Colors.primary.black};
  width: 27px;
  height: 27px;
  padding-left: 5px;
  margin-right: 5px;
`;

export const IconUnselected = styled.div`
  background-image: url("./icon_unselected.png");
  background-repeat: no-repeat;
  background-position: left;
  background-color: ${Colors.primary.black};
  width: 27px;
  height: 27px;
  padding-left: 5px;
  margin-left: 2px;
`;
