import React from "react";
import PropTypes from "prop-types";
import DocumentIcon from "../DocumentIcon/DocumentIcon";
import * as S from "./DocumentResultCard.styles";
import { textTruncate } from "../../utils/text-utils";
import renderHTML from 'react-render-html';

const DocumentResultCard = ({ result }) => {
  const { title, summary, type, source } = result;
  return (
    <S.Card>
      <a href={source} target="_blank" rel="noopener noreferrer">
        <DocumentIcon type={type} />
        <S.Result>
          <S.Title>{textTruncate(title, 40)}</S.Title>
          <S.Summary>{renderHTML(summary)}</S.Summary>
        </S.Result>
      </a>
    </S.Card>
  );
};

DocumentResultCard.propTypes = {
  result: PropTypes.object,
};

DocumentResultCard.defaultProps = {
  result: {
    title: `Title Text`,
    summary: `Summary Text`,
    type: `pdf`,
    source: `/`,
  },
};

export default DocumentResultCard;
