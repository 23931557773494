import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./utils/custom-aws-config";

import { GlobalStyle } from "./styles/Global";
import { GlobalType } from "./styles/Type";

require("dotenv").config(); // For .env files
const oauthconfig = awsconfig.oauth;

Amplify.configure(awsconfig);
Auth.configure({ oauthconfig });

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <GlobalType />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
