import React from "react";
import PropTypes from "prop-types";
import * as S from "./ContactSummary.styles";
import * as vCardsJS from "@abumalick/vcards-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { textTruncate } from "../../utils/text-utils";

const ContactSummary = ({
  link,
  name,
  image,
  title,
  company,
  phones,
  addresses,
  email,
  owner
}) => {
  const getPhone = (phones) => {
    if (phones !== undefined) {
      let phone = phones.filter((phone) => phone.type === "Business");
      if (phone !== undefined && phone.length > 0) {
        return phone[0].phone;
      }
      phone = phones.filter((phone) => phone.type === "Mobile");
      if (phone !== undefined && phone.length > 0) {
        return phone[0].phone;
      }
      phone = phones.filter((phone) => phone.type === "Google Contact");
      if (phone !== undefined && phone.length > 0) {
        return phone[0].phone;
      }
    }
    return "";
  };
  const downloadVCard = async (name, title, company, photo, email, phones, addresses) => {
    try {
      const vCard = vCardsJS();
      vCard.version = "4";
      vCard.firstName = name;
      vCard.formattedName = name;
      vCard.title = title;
      vCard.organization = company;
      // vCard.email = email;
      let photoBase64 = await fetchAsBlob(photo).then(convertBlobToBase64);

      if (photoBase64.indexOf("png") >= 0) {
        photoBase64 = photoBase64.replace("data:image/png;base64,", "");
        vCard.photo.embedFromString(photoBase64, "PNG");
      } else if (photoBase64.indexOf("jpeg") >= 0) {
        photoBase64 = photoBase64.replace("data:image/jpeg;base64,", "");
        vCard.photo.embedFromString(photoBase64, "PNG");
      } else if (photoBase64.indexOf("jpg") >= 0) {
        photoBase64 = photoBase64.replace("data:image/jpeg;base64,", "");
        vCard.photo.embedFromString(photoBase64, "JPB");
      } else if (photoBase64.indexOf("gif") >= 0) {
        photoBase64 = photoBase64.replace("data:image/jpeg;base64,", "");
        vCard.photo.embedFromString(photoBase64, "GIF");
      } else {
        console.log("Unrecognized photo format");
      }

      // if (phones.length > 0) {
        // phones.forEach((phone) => {
          // if (phone.type === "Business") {
            // vCard.workPhone = phone.phone;
          // } else if (phone.type === "Fax") {
            // vCard.workFax = phone.phone;
          // } else if (phone.type === "Mobile") {
            // vCard.cellPhone = phone.phone;
          // } else {
            // vCard.homePhone = phone.phone;
          // }
        // });
      // }
      if (addresses.length > 0) {
        addresses.forEach((addr) => {
          if (addr.type === "Home") {
            vCard.homeAddress.street = addr.street;
            vCard.homeAddress.street2 = addr.street2 ?? "";
            vCard.homeAddress.city = addr.city;
            vCard.homeAddress.stateProvince = addr.state;
            vCard.homeAddress.state = addr.state;
            vCard.homeAddress.zipcode = addr.zipcode;
            vCard.homeAddress.countryRegion = addr.country;
            vCard.homeAddress.postalCode = addr.zipcode;
          } else if (addr.type === "Business") {
            vCard.workAddress.street = addr.street;
            vCard.workAddress.street2 = addr.street2 ?? "";
            vCard.workAddress.city = addr.city;
            vCard.workAddress.stateProvince = addr.state;
            vCard.workAddress.state = addr.state;
            vCard.workAddress.zipcode = addr.zipcode;
            vCard.workAddress.countryRegion = addr.country;
            vCard.workAddress.postalCode = addr.zipcode;
          } else {
            vCard.otherAddress.street = addr.street;
            vCard.otherAddress.street2 = addr.street2 ?? "";
            vCard.otherAddress.city = addr.city;
            vCard.otherAddress.stateProvince = addr.state;
            vCard.otherAddress.state = addr.state;
            vCard.otherAddress.zipcode = addr.zipcode;
            vCard.otherAddress.countryRegion = addr.country;
            vCard.otherAddress.postalCode = addr.zipcode;
          }
        });
        if (owner !== undefined && owner.length > 0) {
          vCard.note = `Manager is ${owner}`;
        }
  
      }

      const url = window.URL.createObjectURL(
        new Blob([vCard.getFormattedString()], {
          type: "text/vcard;charset=utf-8",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.vcf`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log('V CaRD E', e);
    }
  };

  const fetchAsBlob = (url) => fetch(url, {
    method: 'GET',
    mode: 'no-cors',
    cache: 'default',
  }).then((response) => response.blob());

  const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  return (
    <S.SummaryText>
      <div>
        {textTruncate(name, 25)}{" "}
        <S.VCardLink>
          {/* eslint-disable-next-line */}
          <div
            onClick={(e) => {
              e.preventDefault();
              downloadVCard(name, title, company, image, email, phones, addresses);
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faAddressCard} />
          </div>
        </S.VCardLink>
      </div>

      <div style={{ fontWeight: 300 }}>{title}</div>
      <div style={{ fontWeight: 300 }}>{company}</div>
      <div style={{ fontWeight: 300 }}>
        {textTruncate(getPhone(phones), 25, "")}
      </div>
      <div style={{ fontWeight: 300 }}>{textTruncate(email, 33, "")}</div>
    </S.SummaryText>
  );
};

ContactSummary.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
};

// Empty Character
// http://emptycharacter.com/
// Default props are an invisible whitespace character
// To preserve the vertical layout when missing data
// Unicode: U+0020
// HTML: &#32
// Copy/paste: ‏‏‎ ‎

ContactSummary.defaultProps = {
  name: `‏‏‎ ‎`,
  title: `‏‏‎ ‎`,
  company: `‏‏‎ ‎`,
};

export default ContactSummary;
