import React from "react";
import PropTypes from "prop-types";
import * as S from "./RefineMenuIcon.styles";
import RefineIconMap from "./RefineIconMap";

const RefineMenuIcon = ({ active, icon }) => {
  const refinement = RefineIconMap("refinement");
  const refinementBlue = RefineIconMap("refinement_blue");
  return (
    <>
      {active ? (
        <S.ActiveIcon>
          <img src={refinementBlue} alt={`Results Active Icon`} width="80" />
        </S.ActiveIcon>
      ) : (
        <S.InactiveIcon>
          <img src={refinement} alt={`Results Inactive Icon`} width="80" />
        </S.InactiveIcon>
      )}
    </>
  );
};

RefineMenuIcon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
};

RefineMenuIcon.defaultProps = {
  active: true,
  icon: `refinement`,
};

export default RefineMenuIcon;
