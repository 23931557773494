import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Container = styled.div`
background-color: ${(props) => (props.active ? Colors.primary.white : Colors.primary.black)};
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Highlight = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 114px;
  background-color: ${(props) => (props.active ? Colors.primary.white : Colors.primary.black)};
  /* Active/Inactive Styles */
  border-left: 5px solid
    ${(props) => (props.active ? Colors.primary.yellow : Colors.primary.black)};
  border-right: 5px solid {Colors.primary.black};
`;

export const Divider = styled.div``;

export const Label = styled.span`
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  /* Active/Inactive Styles */
  color: ${(props) =>
    props.active ? Colors.primary.black : Colors.primary.gray};
`;

export const Icon = styled.div`
  width: 110px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items center;
`;
