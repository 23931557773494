import React from "react";
import PropTypes from "prop-types";
import * as S from "./SearchHeader.styles";

const SearchHeader = ({ children }) => {
  return (
    <S.Header>
      {children}
    </S.Header>
  );
};

SearchHeader.propTypes = {
  children: PropTypes.node,
};

SearchHeader.defaultProps = {
  children: undefined,
};

export default SearchHeader;
