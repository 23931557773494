import React from "react";
import PropTypes from "prop-types";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import * as S from "./ResultsExpand.styles";
import ToolTip from '../ToolTip/ToolTip';
const ResultsExpand = ({ shouldExpand, isExpanded, handleToggle }) => {
  return (
    <S.IconWrapper>
      {shouldExpand && (
        <>
          {isExpanded ? (
            <ToolTip placement="top" trigger="hover" tooltip="Collapse Results">
              <S.IconStack onClick={(e) => handleToggle(e)}>
                <S.Icon icon={faChevronUp} />
                <S.Icon2 icon={faChevronUp} />
              </S.IconStack>
            </ToolTip>
          ) : (
              <ToolTip placement="top" trigger="hover" tooltip="Expand Results">
                <S.IconStack onClick={(e) => handleToggle(e)} data-tip="">

                  <S.Icon icon={faChevronDown} />
                  <S.Icon2 icon={faChevronDown} />

                </S.IconStack>
              </ToolTip>
            )}
        </>
      )
      }

    </S.IconWrapper >

  );
};

ResultsExpand.propTypes = {
  shouldExpand: PropTypes.bool,
  isExpanded: PropTypes.bool,
  handleToggle: PropTypes.func,
};

ResultsExpand.defaultProps = {
  shouldExpand: false,
  isExpanded: false,
};

export default ResultsExpand;
