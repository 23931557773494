import React from "react";
import PropTypes from "prop-types";
import * as S from "./RefineOptionIcon.styles";

const RefineOptionIcon = ({ selected }) => {
  return <>{selected ? <S.IconSelected /> : <S.IconUnselected />}</>;
};

RefineOptionIcon.propTypes = {
  selected: PropTypes.bool,
};

RefineOptionIcon.defaultProps = {
  selected: false,
};

export default RefineOptionIcon;
