import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Card = styled.div`
  width: 30%;
  min-width: 300px;
  border-bottom: 15px solid ${Colors.primary.black};
  height: 120px;
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  font-weight: 300;
  margin: 10px;
`;
