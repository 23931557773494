import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Container = styled.div`
  border-bottom: 2px solid ${Colors.primary.gray};
  height: 100px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Header = styled.div`
  text-align: center;
`;

export const Title = styled.span`
  text-transform: uppercase;
  color: ${Colors.grays.medium};
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  > div {
    width: 150px;
    margin:0px 5px;
  }
`;
