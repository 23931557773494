export const primary = {
  yellow: "rgba(255, 199, 44, 1)",
  black: "rgba(51, 63, 72, 1)",
  gray: "rgba(217, 217, 214, 1)",
  white: "rgba(255,	255,	255, 1)"
};

export const grays = {
  dark: "rgba(77, 87, 97, 1)",
  medium: "rgba(115, 123, 131, 1)",
  light: "rgba(156, 162, 167, 1)",
  faint: "rgba(198, 202, 205, 1)",
};
