import React from "react";
import PropTypes from "prop-types";
import CompanyLogo from "../CompanyLogo/CompanyLogo";
import { textTruncate } from "../../utils/text-utils";
import * as S from "./CompanyCard.styles";

const CompanyCard = ({ id, name, logo, text, locations }) => {
  const link = `https://heritage.affinity.co/companies/${id}`;


  return (
    <S.Card>
      <div style={{ padding: "5px" }}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <CompanyLogo image={logo} alt={name} />
          <S.Result>
            <S.Title>{textTruncate(name, 40)}</S.Title>
            <S.Text>{text}</S.Text>            
          </S.Result>
        </a>
      </div>
      <S.Row>
        <S.BottomDetail>
          <div >
            Locations
            <br />
            {locations.match(/[a-zA-Z0-9]/g) ?? [].length > 0 ? textTruncate(locations.replace('-',''),70) : "Not Available"}
          </div>

        </S.BottomDetail>
      </S.Row>
    </S.Card>
  );
};



CompanyCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  logo: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};

// Empty Character
// http://emptycharacter.com/
// Default props are an invisible whitespace character
// To preserve the vertical layout when missing data
// Unicode: U+0020
// HTML: &#32
// Copy/paste: ‏‏‎ ‎

CompanyCard.defaultProps = {
  name: ` ‎`,
  logo: ``,
  text: ` ‎`,
};

export default CompanyCard;
