import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const HeroWrapper = styled.div`
  width: 100%;
  height: 458px;
  border-bottom: 8px solid ${Colors.primary.yellow};
  background-color: ${Colors.primary.gray};
`;

export const HeroImage = styled.div`
  background-size: cover;
  margin: 0 auto;
  height: 458px;
  background-image: url("./thg_night.jpg");
  background-repeat: no-repeat;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  > form {
    width: 75%;
    margin: 0 auto;
    margin-top: 3px;
    padding-right: 20px;
  }
`;
