import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Colors from "../../styles/Colors";

export const Wrapper = styled.div`
  position: relative;
  width: 300px;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 1em;
  top: 20%;
  right: 0;
  color: ${Colors.primary.gray};
`;

export const SearchInput = styled.input`
  width: 300px;
  font-size: 0.8em;
  padding: 5px;
  padding-left: 10px;
  border-radius: 1.2em;
  background-color: ${Colors.primary.black};
  color: ${Colors.primary.gray};
  &::placeholder {
    color: ${Colors.primary.gray};
  }
  &:-ms-input-placeholder {
    color: ${Colors.primary.gray};
  }
  &::-ms-input-placeholder {
    color: ${Colors.primary.gray};
  }
  &:focus {
    outline: none;
  }
`;
