import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Title = styled.span`
  font-weight: 700;
`;

export const Heading = styled.a`
  cursor: pointer;
  display: inline-block;
  padding-bottom: 0;
`;

export const Body = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  height: 70px;
  display:block;
`;

export const Card = styled.div`
  border: 2px solid ${Colors.primary.black};
  cursor: pointer;
  color: ${Colors.primary.black};
  width:30%;
  height: 165px;
  padding: 8px;
  margin: 10px;
  position: relative;
  overflow:hidden;
  border-bottom: 15px solid ${Colors.primary.black};
  background-color: white;
  a {
    text-decoration: none;
    color: ${Colors.primary.black};
  }
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
      width: 100%;

  }
`;
