import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.span`
  text-transform: uppercase;
  color: ${Colors.grays.medium};
`;

export const Options = styled.div`
  height: auto;
  > div {
    margin-bottom: 10px;
  }
`;
