import RELATIONSHIPS from "./icon_relationships.png";
import DEALS from "./icon_deals_lists.png";
import DOCUMENTS from "./icon_documents.png";
import WEB from "./icon_web.png";
import ARTICLES from "./icon_articles.png";

const lookup = {
  RELATIONSHIPS,
  DEALS,
  ARTICLES,
  DOCUMENTS,
  WEB,
};

const IconMap = (icon) => {
  return lookup[icon.toUpperCase()] || "";
};

export default IconMap;
