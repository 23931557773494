import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import awsconfig from "./utils/custom-aws-config";
import { GetProfile } from "./API";

const DOMAIN = awsconfig.oauth.domain;
const PROVIDER = process.env.REACT_APP_PROVIDER;
const REDIRECT = process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN;
const RESPONSETYPE = "TOKEN";
const CLIENTID = process.env.REACT_APP_OAUTH_CLIENT_ID;
const SCOPE =
  "aws.cognito.signin.user.admin%20email%20openid%20phone%20profile";

const signinUrl = `https://${DOMAIN}/oauth2/authorize?identity_provider=${PROVIDER}&redirect_uri=${REDIRECT}&response_type=${RESPONSETYPE}&client_id=${CLIENTID}&scope=${SCOPE}`;

const UserContext = React.createContext();
//UserContext.Provider
//UserContext.Consumer

export function UserProvider({ children }) {
  const [auth, setAuth] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [profile, _setProfile] = useState({});
  const [relationshipsEnabled, setRelationshipsEnabled] = useState(true);
  const [dealsAndListsEnabled, setDealsAndListsEnabled] = useState(true);
  const [articlesEnabled, setArticlesEnabled] = useState(true);
  const [webEnabled, setWebEnabled] = useState(true);


  function setPermissions (permissions)
  {
    permissions = permissions || [];

    setRelationshipsEnabled (!permissions.length || permissions.includes ("view:relationships"));
    setDealsAndListsEnabled (!permissions.length || permissions.includes ("view:deals"));
    setArticlesEnabled (!permissions.length || permissions.includes ("view:articles"));
    setWebEnabled (!permissions.length || permissions.includes ("view:web"));
  }

  function setProfile (profile)
  {
    setPermissions (profile && profile.permissions);
    _setProfile (profile);
  }


  useEffect(() => {
    Auth.currentSession()
      .then((results) => {
        setToken(results.idToken.jwtToken);
        setAuth(true);
        setUser(results.idToken.payload.email);

        return GetProfile ()
            .then ((p) =>
            {
                setProfile (p);
            })
            .catch (() =>
            {
                setProfile ({});
            })
        ;
      })
      .catch((e) => {
        setAuth(false);
        setUser(null);
        setToken(null);
        setProfile ({});
      })
      ;
  }, []);

  const signout = () => {
    Auth.signOut()
      .then(() => {
        setAuth(null);
        setUser(null);
        setToken(null);
        setProfile ({});
      })
      .catch((e) => {
        console.log("Error signing out: ", e);
      });
  };

  const signin = () => {
    window.location.assign(`${signinUrl}`);
  };

  const userValue = {
    user,
    auth,
    signin,
    signout,
    relationshipsEnabled,
    dealsAndListsEnabled,
    articlesEnabled,
    webEnabled,
    setPermissions
  };

  return (
    <UserContext.Provider value={userValue}>
      <>{children}</>
    </UserContext.Provider>
  );
}

export default UserContext;
