import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const PanelRow = styled.div`
  background-color: ${Colors.primary.gray};
  padding: 40px;
  text-transform: uppercase;
  font-weight: 700;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  border-top: 5px solid ${Colors.primary.black};
  border-bottom: 5px solid ${Colors.primary.black};
  margin-bottom: 20px;
`;

export const PanelRowItems = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
`;
