import React from "react";
import PropTypes from "prop-types";
import * as S from "./MenuIcon.styles";
import IconMap from "./IconMap";

const MenuIcon = ({ active, icon }) => {
  const type = IconMap(icon);

  return (
    <>
      {active ? (
        <S.ActiveIcon>
          <img src={type} alt={`Results Active Icon`} width="80" />
        </S.ActiveIcon>
      ) : (
        <S.InactiveIcon>
          <img src={type} alt={`Results Inactive Icon`} width="80" />
        </S.InactiveIcon>
      )}
    </>
  );
};

MenuIcon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
};

MenuIcon.defaultProps = {
  active: true,
  icon: `web`,
};

export default MenuIcon;