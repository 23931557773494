import React, { memo, useState, useEffect, useCallback } from "react";
import ResultsRow from "./ResultsRow";
import ResultsRowHeader from "../ResultsRowHeader/ResultsRowHeader";
import TextResultCard from "../TextResultCard/TextResultCard";
import * as Count from "../../utils/results-count";
import * as S from "./styles.common";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import _ from "lodash";
import AdvancedSearchButton from "../../components/AdvancedSearch/AdvancedSearchButton";

const ArticleResults = memo (({ results, handleInsightsSortSearchRef }) => {
  const [shouldExpand, setShouldExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [origArticleObjs, setOrigArticleObjs] = useState();
  const [sortedArticleObjs, setSortArticleObjs] = useState();
  const [defaultSortState, setDefaultSortState] = useState('Relevance');

  const setArticleObjs = useCallback (() => {
    if (results && results.articleResults) {      
      setOrigArticleObjs(_.cloneDeep(results.articleResults));
      setSortArticleObjs(results.articleResults);
    }
  }, [results]);

  const sortInsights = async (e) => {
    let objs;
    let orderBy;
    console.log('Sort ', e);
    if (e.value === 'Published: Newest to Oldest') {
      orderBy = 'recent';
    } else if (e.value === 'Published: Oldest to Newest') {
      orderBy = 'oldest';
    } else if (e.value === 'Updated: Most Recent to Oldest') {
      orderBy = 'recent_updated';
    } else if (e.value === 'Updated: Oldest to Most Recent') {
      orderBy = 'oldest_updated';
    } else if (e.value === 'Popularity') {
      orderBy = 'popularity';
    } else if (e.value === 'Alphabetical') {
      orderBy = 'name';
    }
    console.log('Sort ', orderBy);
    if (e.value === 'Relevance') {
      objs = _.cloneDeep(origArticleObjs);
    } else {
      const rep = await handleInsightsSortSearchRef.current (orderBy);
      objs = rep.bloomfire;
    }

    console.log('INSIGHT REP', objs);
    setSortArticleObjs(objs);

  }

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setDefaultSortState('Relevance');
    setArticleObjs();
    if (Count.Articles(results) > 6) {
      setShouldExpand(true);
    }
  }, [results, setArticleObjs]);
  return (
    <ResultsRow>
      <ResultsRowHeader
        type={`Insights`}
        count={Count.Articles(results)}
        shouldExpand={shouldExpand}
        isExpanded={isExpanded}
        handleToggle={handleExpandToggle}
      />
      {sortedArticleObjs && (
        <>
        <S.Filter >
          <Dropdown options={[
            'Relevance', 'Published: Newest to Oldest', 'Published: Oldest to Newest', 'Updated: Most Recent to Oldest', 'Updated: Oldest to Most Recent', 'Popularity', 'Alphabetical'
          ]} value={defaultSortState} onChange={(e) => {setDefaultSortState(e.value);  sortInsights(e);}} placeholder="Select an option" />
        </S.Filter>
         <AdvancedSearchButton resultType='Insights'></AdvancedSearchButton>
        </>
      )}
      {sortedArticleObjs && (

        <S.ArticleResults isExpanded={isExpanded}>

          {sortedArticleObjs.map((article) => (
            <TextResultCard
              key={article.instance.id}
              result={{
                title: article.instance.name,
                text: article.instance.description,
                source: article.instance.url,
              }}
            />
          ))}
        </S.ArticleResults>
      )}
    </ResultsRow>
  );
});

export default ArticleResults;
