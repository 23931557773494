import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const ResultsRow = styled.div`
  background-color: ${Colors.primary.gray};
  min-height: 160px;
  padding: 20px;
  border-bottom: 3px solid ${Colors.primary.black};
  &:nth-of-type(odd) {
    background-color: ${Colors.grays.light};
  }
`;

export const DealsResults = styled.div`
  width: 100%;
  max-height: ${(props) => (props.isExpanded ? "calc(2 * 340px)" : "390px")};
  overflow-y: ${(props) => (props.isExpanded ? "scroll" : "hidden")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const WebResults = styled.div`
  width: 100%;
  max-height: ${(props) => (props.isExpanded ? "calc(2 * 340px)" : "420px")};
  overflow-y: ${(props) => (props.isExpanded ? "scroll" : "hidden")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DocResults = styled.div`
  width: 100%;
  max-height: ${(props) => (props.isExpanded ? "calc(2 * 340px)" : "420px")};
  overflow-y: ${(props) => (props.isExpanded ? "scroll" : "hidden")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ArticleResults = styled.div`
  width: 100%;
  max-height: ${(props) => (props.isExpanded ? "calc(2 * 340px)" : "420px")};
  overflow-y: ${(props) => (props.isExpanded ? "scroll" : "hidden")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ContactResults = styled.div`
  width: 100%;
  max-height: ${(props) => (props.isExpanded ? "calc(2 * 400px)" : "400px")};
  overflow-y: ${(props) => (props.isExpanded ? "scroll" : "hidden")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const CompanyResults = styled.div`
  width: 100%;
  max-height: ${(props) => (props.isExpanded ? "calc(2 * 295px)" : "400px")};
  overflow-y: ${(props) => (props.isExpanded ? "scroll" : "hidden")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const Filter =styled.div`
  margin: 0px;
  padding: 0px;
  width:250px;
  float:left;
`;
export const SortOrderButton = styled.div`
color: ${Colors.primary.black};
float:left;
cursor: pointer;
  margin-top:3px;
  margin-right:5px;
  &:hover {
    cursor: pointer;
    color: ${Colors.primary.yellow};
  }
`;