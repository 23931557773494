import React, { memo, useState, useEffect, useCallback } from "react";
import ResultsRow from "./ResultsRow";
import ResultsRowHeader from "../ResultsRowHeader/ResultsRowHeader";
import ContactSummaryCard from "../../components/ContactSummaryCard/ContactSummaryCard";
import CompanyCard from "../../components/CompanyCard/CompanyCard";
import * as Count from "../../utils/results-count";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import * as S from "./styles.common";
import sorter from 'sort-nested-json';
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaUp, faSortAlphaDown } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
import AdvancedSearchButton from "../../components/AdvancedSearch/AdvancedSearchButton";


const RelationshipResults = memo (({ results }) => {
  const [shouldExpand, setShouldExpand] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [origContactObjs, setOrigContactObjs] = useState();
  const [origCompanyObjs, setOrigCompanytObjs] = useState();
  const [sortedContactObjs, setSortContactObjs] = useState();
  const [sortedCompanyObjs, setSortCompanyObjs] = useState();
  const [sortContactDescending, setSortContactDescending] = useState(false);
  const [sortCompanyDescending, setSortCompanyDescending] = useState(false);
  const [contactSortFieldState, setContactSortField] = useState('');
  const [companySortFieldState, setCompanySortField] = useState('');
  const [defaultSortState, setDefaultSortState] = useState('Relevance');

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const setResults = useCallback (() => {
    if (results && results.relationships && results.relationships.contacts) {
      const newContacts = [];
      results.relationships.contacts.forEach(contact => {
        try{
         if(contact.full_name == null) {
          console.log(contact);
        }
        const nameParts = contact.full_name.split(' ');
        const lastName = nameParts[nameParts.length - 1];
        if (nameParts.length > 1) {
          nameParts.pop();
        }
        try {
          const firstName = nameParts.join(' ');
          contact.first_name = firstName;
          contact.last_name = lastName;
          try {
            contact.relationship_strength = parseInt(contact.relationship_strength);
          } catch (e) {
            contact.relationship_strength = 0;
          }
          if (contact.last_interaction !== 'No activity found') {
            const dt = moment(`${contact.last_interaction} `);
            contact.last_interaction_int = dt;
            newContacts.push(contact);
          } else {
            contact.last_interaction_int = 0;
            newContacts.push(contact);
          }
        } catch (e) {
          console.log(e,' ',contact);
        }
      }catch(e) {
        console.log(e);
      }
      });
      setOrigContactObjs(_.cloneDeep(newContacts));
      setSortContactObjs(newContacts);
    } else if (results && results.relationships && results.relationships.contacts === undefined) {
      setOrigContactObjs(_.cloneDeep([]));
      setSortContactObjs(undefined);
    }

    if (results && results.relationships && results.relationships.companies) {
      setOrigCompanytObjs(_.cloneDeep(results.relationships.companies));
      setSortCompanyObjs(results.relationships.companies);
    } else if (results && results.relationships && results.relationships.companies === undefined) {
      setOrigCompanytObjs(_.cloneDeep([]));

      setSortCompanyObjs(undefined);
    }
  }, [results]);

  const sortContacts = (e, sortDir) => {
    sortDir = sortDir ?? sortContactDescending;
    let sortField;
    if (e.value === 'First Name') {
      sortField = 'first_name';
    } else if (e.value === 'Last Name') {
      sortField = 'last_name';
    } else if (e.value === 'Location') {
      sortField = 'location';
    } else if (e.value === 'Last Interaction') {
      sortField = 'last_interaction_int';
    } else if (e.value === 'Relationship Strength') {
      sortField = 'relationship_strength';
    } else if (e.value === 'Relevance') {
      sortField = 'relevance';
    }
    if (sortField !== undefined) {
      let objs = sortedContactObjs;
      if (sortField === 'relevance') {
        // we depp clone here becase origObj will eventually get set to the sorted array by reference.
        objs = _.cloneDeep(origContactObjs);
      } else {
        console.log('sort ', sortField, ' ', sortDir);
        if (sortField === 'relationship_strength' && sortDir) {
          objs = sorter.sort(objs).asc('relationship_strength');
        } else if (sortField === 'relationship_strength' && !sortDir) {
          objs = sorter.sort(objs).desc('relationship_strength');
        } else {
          if (sortDir) {
            objs = sorter.sort(objs).desc(sortField);
          } else {
            objs = sorter.sort(objs).asc(sortField);
          }
        }

      }
      setSortContactObjs(objs);
      setContactSortField(e.value);
    }
  }

  const sortCompanies = (e, sortDir) => {
    console.log('Select company ', e.value);
    let sortField;
    if (e.value === 'Name') {
      sortField = 'name';
    } else if (e.value === 'Location') {
      sortField = 'locationList';
    } else if (e.value === 'Last Interaction') {
      sortField = 'last_interactionUnix';
    } else if (e.value === 'Relevance') {
      sortField = 'relevance';
    }
    if (sortField !== undefined) {
      let objs = sortedCompanyObjs;
      if (sortField === 'relevance') {
        // we depp clone here becase origObj will eventually get set to the sorted array by reference.
        objs = _.cloneDeep(origCompanyObjs);
      } else {

        console.log('sort ', sortField);
        if (sortDir) {
          objs = sorter.sort(objs).desc(sortField);
        } else {
          objs = sorter.sort(objs).asc(sortField);
        }

      }
      setSortCompanyObjs(objs);
      setCompanySortField(e.value);
    }
  }

  const setContactSortDir = () => {
    console.log('DIR ', sortContactDescending);
    setSortContactDescending(!sortContactDescending);
    sortContacts({ value: contactSortFieldState }, !sortContactDescending);
  }
  const setCompanySortDir = () => {
    console.log('DIR ', sortCompanyDescending);
    setSortCompanyDescending(!sortCompanyDescending);
    sortCompanies({ value: companySortFieldState }, !sortCompanyDescending);
  }
  useEffect(() => {
    setDefaultSortState('Relevance');
    setResults();
    
    if (Count.Relationships(results) > 6) {
      setShouldExpand(true);
    }

  }, [results, setResults]);
  return (
    <ResultsRow>
      <ResultsRowHeader
        type={`relationships`}
        count={Count.Relationships(results)}
        shouldExpand={shouldExpand}
        isExpanded={isExpanded}
        handleToggle={handleExpandToggle}
      />

      {sortedContactObjs && (
        <>
          <h3 style={{ textTransform: "uppercase" }}>Contacts</h3>
          <S.SortOrderButton onClick={(e) => setContactSortDir(e)}>
            {sortContactDescending ? <FontAwesomeIcon icon={faSortAlphaDown} size="2x" /> : <FontAwesomeIcon icon={faSortAlphaUp} size="2x" />}
          </S.SortOrderButton>
          <S.Filter>
            <Dropdown options={[
              'First Name', 'Last Name', 'Location', 'Last Interaction', 'Relationship Strength', 'Relevance'
            ]} value={defaultSortState} onChange={(e) => {setDefaultSortState(e.value); sortContacts(e);}} placeholder="Select an option" />
          </S.Filter>
          <AdvancedSearchButton resultType='Contacts'></AdvancedSearchButton>
          <S.ContactResults isExpanded={isExpanded}>
            {sortedContactObjs.map((contact) => (

              <ContactSummaryCard
                contact={contact}
                name={contact.full_name}
                title={contact.job_role}
                company={contact.job_company}
                image={contact.photo}
                id={contact.id}
                email={contact.email}
                phones={contact.phone_list}
                addresses={contact.address_list}
                owner={contact.owner}
                location={contact.location}
                lastInteraction={contact.last_interaction}
                relationship={contact.relationship_strength}
                key={contact.id}
              />
            ))}
          </S.ContactResults>
        </>
      )}
      {sortedCompanyObjs && (
        <>
          <h3 style={{ textTransform: "uppercase" }}>Companies</h3>
          <S.SortOrderButton onClick={(e) => setCompanySortDir(e)}>
            {sortCompanyDescending ? <FontAwesomeIcon icon={faSortAlphaDown} size="2x" /> : <FontAwesomeIcon icon={faSortAlphaUp} size="2x" />}
          </S.SortOrderButton>
          <S.Filter>
            <Dropdown options={[
              'Name', 'Location', 'Last Interaction', 'Relevance'
            ]} value={defaultSortState} onChange={(e) => sortCompanies(e)} placeholder="Select an option" />
          </S.Filter>
          <AdvancedSearchButton resultType='Companies'></AdvancedSearchButton>
          <S.CompanyResults isExpanded={isExpanded}>
            {sortedCompanyObjs.map((company) => (
              <CompanyCard
                name={company.name}
                text={company.description.match(/[a-zA-Z0-9]/g) ?? [].length > 0 ? company.description : ''}
                id={company.id}
                key={company.id}
                logo={company.logo}
                locations={company.locationList !== undefined ? company.locationList.join(' ') : ''}
              />
            ))}
          </S.CompanyResults>
        </>
      )}
    </ResultsRow>
  );
});

export default RelationshipResults;
