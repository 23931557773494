import React from "react";
import PropTypes from "prop-types";
import * as S from "./ResultsCount.styles";

const ResultsCount = ({ count }) => {
  return <S.ResultsPill single={count < 10}>{count}</S.ResultsPill>;
};

ResultsCount.propTypes = {
  count: PropTypes.number,
};
ResultsCount.defaultProps = {
  count: 3,
};

export default ResultsCount;
