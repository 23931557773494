export const textTruncate = (str, length, ending = "...") => {
  if (length === null) {
    length = 100;
  }
  if (ending === null) {
    ending = "...";
  }
  if (str && str.length && str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};
