import React from "react";
import PropTypes from "prop-types";
import RefineOptionIcon from "./RefineOptionIcon/RefineOptionIcon";
import * as S from "./RefineOption.styles";

const RefineOption = ({ selected, label, handleToggle }) => {
  return (
    <S.Container onClick={handleToggle}>
      <RefineOptionIcon selected={selected} />
      <S.Label>{label}</S.Label>
    </S.Container>
  );
};

RefineOption.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  handleToggle: PropTypes.func,
};

RefineOption.defaultProps = {
  selected: false,
  label: `Open Presentation`,
};

export default RefineOption;
