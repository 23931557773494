import React from "react";
import PropTypes from "prop-types";
import MenuIcon from "../MenuToggleIcon/MenuIcon";
import * as S from "./MenuToggleButton.styles";

const MenuToggleButton = ({ isActive, label, icon, handleToggle }) => {
  return (
    <S.Container onClick={handleToggle}>
      <S.Highlight active={isActive}>
        <S.Icon>
          <MenuIcon active={isActive} icon={icon} />
        </S.Icon>
        <S.Label active={isActive}>{label}</S.Label>
      </S.Highlight>
    </S.Container>
  );
};

MenuToggleButton.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  handleToggle: PropTypes.func,
};
MenuToggleButton.defaultProps = {
  isActive: false,
  label: `Relationships`,
  icon: `relationships`,
};

export default MenuToggleButton;