import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const Profile = styled.div`
  width: 20%;
  overflow: hidden;
  padding: 5px;
  font-size: 100px;
  color: ${Colors.grays.light};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 auto;
  img {
    max-width: 100%;
    height: auto;
  }
`;
