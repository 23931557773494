import React, { useState } from "react";
import MenuHeader from "../MenuHeader/MenuHeader";
import MenuToggleButton from "../MenuToggleButton/MenuToggleButton";
import RefineToggleButton from "../RefineToggleButton/RefineToggleButton";
import RefinePanel from "../RefinePanel/RefinePanel";
import * as S from "./Menu.styles.js";

const Menu = ({
  refineSearchActive,
  relationshipsActive,
  dealsAndListsActive,
  documentsActive,
  webActive,
  articlesActive,
  setRefineSearchActive,
  setRelationshipsActive,
  setDealsAndListsActive,
  setDocumentsActive,
  setWebActive,
  setArticlesActive,
  relationshipsEnabled,
  dealsAndListsEnabled,
  articlesEnabled,
  webEnabled
}) => {
  const [stateObj, setStateObj] = useState({});
  
  const handleRefinementToggle = () => {
    setRefineSearchActive(!refineSearchActive);
  };
  const handleRelationshipToggle = () => {
    setRelationshipsActive(!relationshipsActive);
  };
  const handleDealsAndListsToggle = () => {
    setDealsAndListsActive(!dealsAndListsActive);
  };
  const handleWebToggle = () => {
    setWebActive(!webActive);
  };
  const handleDocumentToggle = () => {
    setDocumentsActive(!documentsActive);
  };
  const handleArticleToggle = () => {
    setArticlesActive(!articlesActive);
  };
  const handleSetStateObj= (obj) => {
    
    setStateObj(obj);
  }
  return (
    <S.MenuBar>
      <MenuHeader />
      <S.RefinementNav>
        <RefineToggleButton
         handleToggle={handleRefinementToggle}
         icon={`refinement`}
         label="Search Refinement"
         isActive={refineSearchActive}
        ></RefineToggleButton>
        
      
        { refineSearchActive?(<RefinePanel handleRefinementToggle={handleRefinementToggle} stateObj={stateObj} handleSetStateObj={handleSetStateObj}/>):(<div/>)}
      </S.RefinementNav>
      <S.CategoryLabel>Categories</S.CategoryLabel>

      {relationshipsEnabled && <MenuToggleButton handleToggle={handleRelationshipToggle} icon={`relationships`} label="Relationships" isActive={relationshipsActive} />}
      {dealsAndListsEnabled && <MenuToggleButton handleToggle={handleDealsAndListsToggle} icon={`deals`} label="deals" isActive={dealsAndListsActive} />}
      {articlesEnabled && <MenuToggleButton handleToggle={handleArticleToggle} icon={`articles`} label="Insights" isActive={articlesActive} />}
      {webEnabled && <MenuToggleButton handleToggle={handleWebToggle} icon={`web`} label="Web" isActive={webActive} />}
      {webEnabled && <MenuToggleButton handleToggle={handleDocumentToggle} icon={`documents`} label="Documents" isActive={documentsActive} />}
    </S.MenuBar>
  );
};

export default Menu;
