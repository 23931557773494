import styled from "styled-components";
import * as Colors from "../../styles/Colors.js";

export const MenuBar = styled.div`
  width: 120px;
  background-color: ${Colors.primary.black};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CategoryLabel = styled.span`
  background-color: ${Colors.primary.black};
  color: ${Colors.primary.gray};
  font-size: 0.9em;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 10px;
`;

export const RefinementNav = styled.div`
  position: relative;
  
`;