import React, { useState, useEffect, useContext } from "react";
import UserContext from "./UserContext";
import { useHistory } from "react-router-dom";
import { queryParamsBuilder, Search, SearchBloomfire } from "./API";
const SearchContext = React.createContext();

const optionsState = (options) => {
  const state = options ?? {};

  return {
    categories: [
      {
        id: "relationships",
        display: "RELATIONSHIPS",
        query: `&relationships=`,
        resultType: ["fourDegrees", "fourDegreesCompanies"],
        options: [
          {
            id: "contacts",
            display: "contacts",
            active: state.contacts ?? true,
          },
          {
            id: "companies",
            display: "companies",
            active: state.companies ?? true,
          },
          {
            id: "deals",
            display: "deals",
            active: state.deals ?? true,
          }
        ],
      },
      {
        id: "webcategories",
        display: "FROM THE WEB",
        query: `&webcategories=`,
        resultType: ["webResults"],
        options: [
          {
            id: "hg ventures",
            display: "HG Ventures",
            active: state.hgVentures ?? true,
          },
          {
            id: "construction",
            display: "Construction",
            active: state.construction ?? true,
          },
          { id: "news", display: "News", active: state.news ?? true },
          {
            id: "environmental",
            display: "Environmental",
            active: state.environmental ?? true,
          },
          {
            id: "chemicals",
            display: "Chemicals",
            active: state.chemicals ?? true,
          },
          { id: "thg", display: "THG", active: state.thg ?? true },
          {
            id: "thg coroporate",
            display: "THG Corporate",
            active: state.thgCorp ?? true,
          },
        ],
      },
      {
        id: "articles",
        display: "ARTICLES",
        query: `&articles=`,
        resultType: ["bloomfire"],
        options: [
          { id: "groups", display: "groups", active: state.groups ?? true },
          {
            id: "categories",
            display: "categories",
            active: state.categories ?? true,
          },
        ],
      },
      {
        id: "documents",
        display: "DOCUMENTS",
        query: `&documents=`,
        resultType: ["webResults"],
        options: [
          { id: "html", display: "html", active: state.html ?? true },
          { id: "pdf", display: "pdf", active: state.pdf ?? true },
          { id: "docx", display: "docx", active: state.docx ?? true },
          { id: "pptx", display: "pptx", active: state.pptx ?? true },
          { id: "xlsx", display: "xlsx", active: state.xlsx ?? true },
          { id: "odt", display: "odt", active: state.odt ?? true },
          { id: "odp", display: "odp", active: state.odp ?? true },
          { id: "ods", display: "ods", active: state.ods ?? true },
        ],
      },
    ],
    sources: [
      {
        id: `sources`,
        options: [
          { id: "web", display: "WEB", active: state.web ?? true },
          {
            id: "fourdegrees",
            display: "4 DEGREES",
            active: state.fourdegrees ?? true,
          },
          {
            id: "bloomfire",
            display: "BLOOMFIRE",
            active: state.bloomfire ?? true,
          },
        ],
      },
    ],
  };
};

export function SearchProvider({ children }) {
  // Search Term state
  const [term, setTerm] = useState("");

  const [options, setOptions] = useState(optionsState());

  const [query, setQuery] = useState("");

  const [results, _setResults] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const { setPermissions } = useContext (UserContext);

  function setResults (r)
  {
      if (r && r.permissions)
      {
          setPermissions (r.permissions);
      }

      _setResults (r);
  }

  useEffect(() => {
    // @TODO: don't search on every key stroke!
    if (!term) {
      return;
    }
    const queryObject = queryParamsBuilder(options, term);

    setQuery(queryObject);
  }, [options, term]);

  const setQueryParams = (options, term) => {
    const optionObj = optionsState(options);
    setOptions (optionObj);

    const queryObject = queryParamsBuilder(optionObj, term);
    setQuery(queryObject);
    setLoading(true);

    Search(queryObject)
      .then((response) => {
        setResults(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onHandleFirstSearch = () => {
    history.push("/results");

    setLoading(true);
    Search(query)
      .then((response) => {
        setResults(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onHandleSearch = (e) => {
    if (e)
    {
      e.preventDefault();
    }

    setResults(null); //clear previous results
    setLoading(true);
    Search(query)
      .then((response) => {
        setResults(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        window.location.reload(false);
      });
  };

  const handleInsightsSortSearch = async (sortOrder) => {
    const response = await new Promise((resolve, reject) => {
      SearchBloomfire(term,sortOrder).then((response) => {
        resolve(response);
      }).catch((e) => {
        window.location.reload(false);
      });
    });
    return response;
  }


  const searchValue = {
    term,
    setTerm,
    onHandleFirstSearch,
    onHandleSearch,
    options,
    setOptions,
    results,
    setResults,
    isLoading,
    setQueryParams,
    handleInsightsSortSearch
  };

  return (
    <SearchContext.Provider value={searchValue}>
      <>{children}</>
    </SearchContext.Provider>
  );
}

export default SearchContext;
