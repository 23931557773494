import styled from "styled-components";
import * as Colors from "../../styles/Colors";

export const SummaryText = styled.div`
  font-weight: 600;
  flex-grow: 1;
  align-self: flex-start;
  width: 80%;
  overflow-wrap: break-word;
`;

export const Details = styled.div`
  background-color: ${Colors.primary.black};
  display:flex;
`;


export const VCardLink = styled.div`
display: inline-block;
margin: 10px 0 0 5px;
div:hover {
  color: ${Colors.primary.yellow};
  cursor: pointer;
}
`;